<template>
  <nav
    class="navbar navbar-expand-lg main-navbar"
    :style="!drawer ? 'left: 0px; ' : ''"
  >
    <div class="form-inline mr-auto">
      <ul class="navbar-nav mr-3">
        <li>
          <a href="/">
          <img src="../../public/images/logo-cf-live.png" alt="" class="logo-image">
          <span class="logo-name pl-1 "> <span style="color:orangered; font-weight: bold;"></span> ag.cf.live </span>
        </a>
        </li>
        <li>
          <a
          style="margin-left: 70px; color: white;"
            href="#"
            data-toggle="sidebar"
            @click="_playSideBar()"
            class="nav-link nav-link-lg collapse-btn"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
        <li>
          <a href="#" class="nav-link nav-link-lg fullscreen-btn text-white">
            <i class="fas fa-expand"></i>
          </a>
        </li>
        <li>
          <b style="font-size: larger; color: white;">
            {{ user ? user.user_name : "" }}:
            <span style="font-weight: bold; color: darkgoldenrod" v-if="user && user.role_id == '612a3fcc1a1fcead8871e822'">
              {{ user ? currencyFormatUsd(user.companyBalances[0].balance) + "$, " + currencyFormatKm(user.companyBalances[1].balance) +
                  "៛" : "" }}
            </span>
            <span style="font-weight: bold; color: darkgoldenrod" v-else-if="user && user.role_id == '6191dc644825e6f57ffa231f' || user && user.role_id == '6191dce94825e65937fa2340' ">
                {{ user ? currencyFormatUsd(user.agent_balance) : "" }}
                <span>{{ getUserCurrency() }}</span>
              </span>
            <span style="font-weight: bold; color: darkgoldenrod" v-else>
              {{ user ? currencyFormatUsd(user.balance) : "" }}
              <span>{{ getUserCurrency() }}</span>
            </span>
           
          </b>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav navbar-right">
      <a @click="onLogout" class="btn btn-danger" style="color: white">
        Logout
      </a>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters("$_modules", {
      drawer: "getDrawer",
      user: "getUser",
    }),
  },
  methods: {
    _playSideBar() {
      this.UPDATE_DRAWER(!this.drawer);
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
        num = parseFloat(num);
        return parseInt(num).toLocaleString("en");
      },
    getUserCurrency() {
      if (this.user.currency_type === 1) {
        return "$";
      } else if (this.user.currency_type === 2) {
        return "៛";
      } else if (this.user.currency_type === 3) {
        return "฿";
      } else {
        return this.user.currency_type;
      }
    },
    async onLogout() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        let data = {
          barcode: dealerId.barcode,
        };
        await this.$request.post({
          url: "dealer/inActiveDealer",
          data: data,
        });
      }
      this.$cookies.remove("dealerId");
      this.$cookies.remove("token");
      this.$router.push("/login");
    },
    ...mapMutations("$_modules", ["UPDATE_DRAWER"]),
  },
};
</script>
<style scoped>
.font {
  font-family: "SegoeUI", "Koh Santepheap" !important;
}
.navbar {
    height: 50px;
    position: fixed;
    right: 0px;
    left: 0px;
    z-index: 890;
    background-color: #39688e;
}
.logo-image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 5px;
}

.logo-name {
  letter-spacing: 1px;
  color: #dcdcdc;
  border-radius: 1px;
  font-weight: 300;
  border-left: 2px solid orangered;
}

.logo-border {
  color: white;
  border-radius: 1px;
  font-weight: 400;

}
</style>